import React, { useState, useEffect } from "react";

const Filter = ({
  onFilterChange,
  checkAvailability,
  availableWeeks,
  availableBedCounts,
  availableSaunaOptions,
  availableBalconyOptions,
}) => {
  const [location, setLocation] = useState("");
  const [week, setWeek] = useState("");
  const [bedCount, setBedCount] = useState("");
  const [qualities, setQualities] = useState("");

  useEffect(() => {
    onFilterChange({
      location,
      week,
      bedCount,
      qualities,
    });
  }, [location, week, bedCount, qualities, onFilterChange]);

  const handleQualitiesChange = (e) => {
    setQualities(e.target.value);
  };

  const handleBedCountChange = (e) => {
    setBedCount(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleWeekChange = (e) => {
    setWeek(e.target.value);
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    onFilterChange({
      location,
      week,
      bedCount,
      qualities,
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="bg-white rounded-xl p-12 w-80 md:w-auto md:p-4 flex flex-col">
      <p className="text-xl md:text-2xl font-bold text-center">Lomahaku</p>
      <form onSubmit={handleFilterSubmit}>
        <div className="flex flex-col md:flex-row items-center">
          {/* Location Select */}
          <div className="flex justify-center mt-2">
            <select
              id="location"
              name="location"
              type="text"
              autoComplete="location"
              className="block w-40 md:w-full rounded-xl border-0 text-black m-1 p-2 shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 focus:ring-teal-700 focus:outline-none"
              placeholder="Sijainti"
              onChange={handleLocationChange}
              value={location}
            >
              <option value="">Sijainti</option>
              {locationOptions.map((loc) => (
                <option
                  key={loc}
                  value={loc}
                  disabled={!checkAvailability("location", loc)}
                  style={{
                    color: checkAvailability("location", loc)
                      ? "black"
                      : "gray",
                  }}
                >
                  {capitalizeFirstLetter(loc)}
                </option>
              ))}
            </select>
          </div>

          {/* Week Select */}
          <div className="flex justify-center mt-2">
            <select
              id="week"
              name="week"
              type="text"
              autoComplete="week"
              className="block w-40 md:w-full rounded-xl border-0 p-2 m-1 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 focus:ring-teal-700 focus:outline-none"
              onChange={handleWeekChange}
              value={week}
            >
              <option value="">Viikko</option>
              {Array.from({ length: 52 }, (_, i) => i + 1).map((weekNumber) => (
                <option
                  key={weekNumber}
                  value={weekNumber}
                  disabled={!availableWeeks.includes(weekNumber)}
                  style={{
                    color: availableWeeks.includes(weekNumber)
                      ? "black"
                      : "gray",
                  }}
                >
                  {`${weekNumber}`}
                </option>
              ))}
            </select>
          </div>

          {/* Bed Count Select */}
          <div className="flex justify-center mt-2">
            <select
              id="bedCount"
              name="bedCount"
              type="text"
              autoComplete="bedCount"
              className="block w-40 md:w-full rounded-xl border-0 p-2 m-1 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 focus:ring-teal-700 focus:outline-none"
              onChange={handleBedCountChange}
              value={bedCount}
            >
              <option value="">Vuodepaikat min.</option>
              {Array.from({ length: 6 }, (_, i) => i + 2).map((count) => (
                <option
                  key={count}
                  value={count}
                  disabled={!availableBedCounts.includes(count)}
                  style={{
                    color: availableBedCounts.includes(count)
                      ? "black"
                      : "gray",
                  }}
                >
                  {`${count}`}
                </option>
              ))}
              <option
                key="8+"
                value="8+"
                disabled={!availableBedCounts.includes(8)}
                style={{
                  color: availableBedCounts.includes(8) ? "black" : "gray",
                }}
              >
                8+
              </option>
            </select>
          </div>

          {/* Qualities Select */}
          <div className="flex justify-center mt-2">
            <select
              id="qualities"
              name="qualities"
              type="text"
              autoComplete="qualities"
              className="block w-40 md:w-full rounded-xl border-0 p-2 m-1 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 focus:ring-teal-700 focus:outline-none"
              onChange={handleQualitiesChange}
              value={qualities}
            >
              <option value="">Ominaisuudet</option>
              <option
                key="sauna"
                value="sauna"
                disabled={!availableSaunaOptions.includes(true)}
                style={{
                  color: availableSaunaOptions.includes(true)
                    ? "black"
                    : "gray",
                }}
              >
                Sauna
              </option>
              <option
                key="balcony"
                value="balcony"
                disabled={!availableBalconyOptions.includes(true)}
                style={{
                  color: availableBalconyOptions.includes(true)
                    ? "black"
                    : "gray",
                }}
              >
                Parveke / Terassi
              </option>
            </select>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-teal-600 text-white rounded-lg px-6 py-2 mt-2 mx-2 hover:bg-teal-700"
          >
            Hae
          </button>
        </div>
      </form>
    </div>
  );
};

export default Filter;

const locationOptions = [
  "airisto",
  "ellivuori",
  "kivijärvi",
  "himos",
  "hoilola",
  "ikaalinen",
  "imatra",
  "kalajoki",
  "vuokatti",
  "koli",
  "kihniö",
  "kuortane",
  "kuusamo",
  "laukaa",
  "levi",
  "naantali",
  "punkaharju",
  "pyhä",
  "rönnäs",
  "ruka",
  "saimaa",
  "salla",
  "saariselkä",
  "tahko",
  "tampere",
  "turku",
  "vierumäki",
  "ylläs",
  "ähtäri",
  "ulkomaat",
];
