import { useEffect, useState, useCallback, useRef } from "react";
import Card from "../components/Card";
import axios from "axios";
import { useAuth } from "../context/authContext";
import ConfirmationModal from "../components/ConfirmationModal";
import Filter from "../components/Filter";
import TimeshareMap from "../components/timeshareMap";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [timeShares, setTimeShares] = useState([]);
  const [filteredTimeShares, setFilteredTimeShares] = useState([]);
  const [filters, setFilters] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedTimeshare, setSelectedTimeshare] = useState(null);
  const [expandedLocation, setExpandedLocation] = useState(null);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const [availableBedCounts, setAvailableBedCounts] = useState([]);
  const [availableSaunaOptions, setAvailableSaunaOptions] = useState([]);
  const [availableBalconyOptions, setAvailableBalconyOptions] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;
  const { user, updateUserCredits } = useAuth();

  const navigate = useNavigate();
  const locationRef = useRef(null);

  // Fetch timeshares once when the component mounts
  useEffect(() => {
    const fetchTimeShares = async () => {
      try {
        const response = await fetch(`${apiUrl}/timeshares/listed`, {
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("data", data);

        setTimeShares(Array.isArray(data) ? data : []);
        updateUserCredits();
      } catch (error) {
        console.error("Error fetching timeShares:", error);
      }
    };

    fetchTimeShares();
  }, [apiUrl, updateUserCredits]);

  useEffect(() => {
    const handleClickInside = (event) => {
      if (locationRef.current && locationRef.current.contains(event.target)) {
        const clickedLocation = event.target.closest("button");
        if (
          !clickedLocation ||
          !Object.keys(locations).includes(
            clickedLocation.innerText.toLowerCase()
          )
        ) {
          setExpandedLocation(null); // Collapse the expanded location if clicked outside a location button
        }
      }
    };

    if (locationRef.current) {
      // Add the event listener if the ref is not null
      locationRef.current.addEventListener("mousedown", handleClickInside);
    }

    return () => {
      // Cleanup the event listener when the component unmounts or ref changes
      if (locationRef.current) {
        locationRef.current.removeEventListener("mousedown", handleClickInside);
      }
    };
  }, [locations]);

  const checkAvailability = useCallback(
    (filterKey, filterValue) => {
      switch (filterKey) {
        case "location":
          return timeShares.some((ts) => ts.location === filterValue);
        case "week":
          return timeShares.some((ts) => {
            const startDate = new Date(ts.start_date);
            return getISOWeek(startDate) === parseInt(filterValue);
          });
        case "bedCount":
          return timeShares.some(
            (ts) => ts.sleeping_places >= parseInt(filterValue)
          );
        case "sauna":
          return timeShares.some((ts) => ts.sauna === 1); // Use 1 for sauna filter
        case "balcony":
          return timeShares.some((ts) => ts.balcony_terrace === 1); // Use 1 for balcony filter
        default:
          return false;
      }
    },
    [timeShares]
  );

  // applyFilters is now safely guarded with useCallback
  const applyFilters = useCallback(() => {
    let filtered = [...timeShares];

    // Filter by location if selected
    if (filters.location) {
      filtered = filtered.filter((ts) => ts.location === filters.location);
    }

    // Calculate available options based on filtered timeshares
    const availableWeeks = new Set();
    const availableBedCounts = new Set();
    const availableSaunaOptions = new Set();
    const availableBalconyOptions = new Set();

    filtered.forEach((ts) => {
      const startDate = new Date(ts.start_date);
      availableWeeks.add(getISOWeek(startDate));
      availableBedCounts.add(ts.sleeping_places);
      if (ts.sauna) {
        availableSaunaOptions.add(true);
      }
      if (ts.balcony_terrace) {
        availableBalconyOptions.add(true);
      }
    });

    setAvailableWeeks(Array.from(availableWeeks));
    setAvailableBedCounts(Array.from(availableBedCounts).sort((a, b) => a - b));
    setAvailableSaunaOptions(Array.from(availableSaunaOptions));
    setAvailableBalconyOptions(Array.from(availableBalconyOptions));

    // Apply filters to get filtered timeshares
    if (filters.week) {
      filtered = filtered.filter((ts) => {
        const startDate = new Date(ts.start_date);
        return getISOWeek(startDate) === parseInt(filters.week);
      });
    }

    if (filters.bedCount) {
      filtered = filtered.filter(
        (ts) => ts.sleeping_places >= parseInt(filters.bedCount)
      );
    }

    if (filters.qualities === "sauna") {
      filtered = filtered.filter((ts) => ts.sauna === 1);
    }

    if (filters.qualities === "balcony") {
      filtered = filtered.filter((ts) => ts.balcony_terrace === 1);
    }

    setFilteredTimeShares(filtered);
  }, [filters, timeShares]);

  // Only apply filters when either timeShares or filters change
  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const onFilterChange = (newFilters) => {
    setFilters((prevFilters) => {
      // Only update filters if they actually change to prevent re-renders
      if (JSON.stringify(newFilters) !== JSON.stringify(prevFilters)) {
        return newFilters;
      }
      return prevFilters;
    });
  };

  const onBookingClick = (timeshare) => {
    if (user.credits >= timeshare.credits) {
      axios
        .put(
          `${apiUrl}/timeshares/book/${timeshare.unit_id}`,
          {
            credits: timeshare.adjustedCredits,
            user: user,
          },
          {
            withCredentials: true,
          }
        )
        .then(() => {
          updateUserCredits();
          setTimeShares((prevTimeShares) =>
            prevTimeShares.filter((ts) => ts.unit_id !== timeshare.unit_id)
          );
          setShowModal(false);
        })
        .catch((error) => {
          console.error("Error during booking process:", error);
          alert("Error booking timeshare.");
        });
    } else {
      alert(
        "Sinulla ei ole tarpeeksi lomakrediittejä tämän viikon varaamiseen."
      );
    }
  };

  const handleBookingClick = (timeshare, adjustedCredits) => {
    setSelectedTimeshare({ ...timeshare, adjustedCredits });
    setShowModal(true);
  };

  const checkOffer = (timeshare) => {
    const currentDate = new Date();
    const timeshareStartDate = new Date(timeshare.start_date);
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(currentDate.getDate() + 30);

    return timeshareStartDate <= thirtyDaysFromNow;
  };

  const handleLocationClick = (location) => {
    setExpandedLocation(expandedLocation === location ? null : location);
  };

  const getISOWeek = (date) => {
    const tempDate = new Date(date);
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
    const week1 = new Date(tempDate.getFullYear(), 0, 4);
    return (
      1 +
      Math.round(
        ((tempDate - week1) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
      )
    );
  };

  const calculateAdjustedCredits = (timeshare) => {
    const currentDate = new Date();
    const timeshareStartDate = new Date(timeshare.start_date);

    const fourWeeksFromNow = new Date();
    fourWeeksFromNow.setDate(currentDate.getDate() + 28);

    if (timeshareStartDate <= fourWeeksFromNow) {
      return Math.floor(timeshare.credits * 0.5);
    }
    return timeshare.credits;
  };

  return (
    <div className="min-h-screen bg-transparent flex flex-col justify-start p-4">
      <div className="flex justify-center">
        <div>
          <div className="bg-white bg-opacity-80 rounded-xl p-4 m-4">
            <h1 className="text-lg md:text-3xl font-bold mx-4 text-center">
              Suomen RCI-tyyppinen lomanvaihto
            </h1>
          </div>
          <div className="bg-white bg-opacity-80 rounded-xl p-4 m-4">
            <h2 className="text-lg md:text-2xl font-bold text-red-500 mx-4 text-center">
              Varaa heti, kaikki lomat 129€/vk
            </h2>
          </div>
        </div>
      </div>
      {user && (
        <div className="flex justify-center">
          <Filter
            onFilterChange={onFilterChange}
            checkAvailability={checkAvailability}
            availableWeeks={availableWeeks}
            availableBedCounts={availableBedCounts}
            availableSaunaOptions={availableSaunaOptions}
            availableBalconyOptions={availableBalconyOptions}
          />
        </div>
      )}
      <div className="flex items-center justify-center">
        {user ? (
          filteredTimeShares.length === 0 ? (
            <p>Ei hakuehtoja vastaavia lomia saatavilla.</p>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {filteredTimeShares.map((timeShare, index) => (
                  <Card
                    key={timeShare.unit_id}
                    timeshare={timeShare}
                    adjustedCredits={calculateAdjustedCredits(timeShare)}
                    buttonText="Varaa nyt"
                    onClick={() =>
                      handleBookingClick(
                        timeShare,
                        calculateAdjustedCredits(timeShare)
                      )
                    }
                    offer={checkOffer(timeShare)}
                  />
                ))}
              </div>
              {showModal && selectedTimeshare && (
                <ConfirmationModal
                  prompt={`Haluatko varmasti varata tämän viikon ${selectedTimeshare.adjustedCredits} lomakrediitillä?`}
                  onConfirm={() => onBookingClick(selectedTimeshare)}
                  onCancel={() => setShowModal(false)}
                />
              )}
            </>
          )
        ) : (
          <div className="flex flex-col justify-center w-9/12">
            <div className="flex flex-col items-center bg-white rounded-xl bg-opacity-50 m-4 w-full lg:w-1/2 mx-auto">
              <p className="text-xl text-center m-4">
                Kirjaudu sisään ja talleta viikko, niin pääset selaamaan
                kohteita ja varaamaan vapaita viikkoja.
              </p>
              <button
                className="bg-teal-600 text-white font-semibold text-lg rounded-lg m-4 p-2 w-1/2 md:w-1/4 hover:bg-teal-700 focus:outline-none"
                onClick={() => navigate("/kirjaudu")}
              >
                Kirjaudu
              </button>
            </div>
            <div className="flex flex-col md:flex-row justify-center items-center mx-auto w-[85%]">
              <div className="w-max md:w-1/2">
                <TimeshareMap />
              </div>
              <div
                className="bg-white rounded-xl ml-2 w-max md:w-2/5 h-min p-4 shadow-lg"
                ref={locationRef} // Attach the ref to the div
              >
                <h2 className="text-xl font-bold mb-2 text-center">Kohteet</h2>
                <ul className="grid grid-cols-3 gap-y-1.5">
                  {Object.keys(locations).map((location) => (
                    <li key={location} className="group">
                      <button
                        onClick={() => handleLocationClick(location)}
                        className="text-lg font-semibold text-gray-800 hover:text-teal-600 focus:outline-none transition-all duration-300 ease-in-out"
                      >
                        {location.charAt(0).toUpperCase() + location.slice(1)}
                      </button>
                      {expandedLocation === location && (
                        <ul className="ml-4 mt-1 space-y-1">
                          {locations[location].map((property) => (
                            <li
                              key={property}
                              className="text-sm text-gray-600 group-hover:text-gray-800 transition-all duration-300 ease-in-out"
                            >
                              {property}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;

const locations = {
  airisto: ["Airiston Fregatti", "Airiston Kuunari", "Airiston Kutteri"],
  ellivuori: ["Ellin Loisto I"],
  kivijärvi: ["Hannunkiven Lomakylä"],
  himos: [
    "Himoksen Aurinkopaikka",
    "Himoksen Tähti I",
    "Villas Himos I",
    "Villas Himos II",
    "Villas Himos III",
  ],
  hoilola: ["Onnenvirta II", "Onnenvirta III"],
  ikaalinen: ["Ikaalisten Mäntypiha"],
  imatra: ["Imatra Spa Viikko Oy"],
  kalajoki: [
    "Särkkäin lomaparatiisi I",
    "Särkkäin lomaparatiisi II",
    "Rantabeach",
    "Kalajoen Keidas",
  ],
  vuokatti: [
    "Katinkullan Golfharju",
    "Katinkullan Hiekkaniemi",
    "Katinkullan Kiinteistöt",
    "Katinkultaniemi",
    "Katinkultaranta",
    "Katinkullan Rantahovi",
    "Katinkulta Residence",
    "Katinkullan Golfpuisto",
    "Katinkulta Spa Lodge 1",
    "Katinkulta Spa Lodge 2",
    "Villas Katinkulta Spa 1",
    "Villas Katinkulta Spa 1 Lodge",
    "Villas Katinkulta Spa 2",
    "Villas Katinkulta Golf Park",
    "Vuokatti Country Club",
    "Vuokatin Kulta-Katti",
    "Vuokatin Lepokatti",
  ],
  koli: ["Kolin Kukkula"],
  kihniö: [
    "Pyhäniemi II",
    "Pyhäniemi III",
    "Pyhäniemi IV",
    "Pyhäniemi V",
    "Pyhäniemi VI",
    "Pyhäniemi VII",
    "Pyhäniemi VIII",
  ],
  kuortane: ["Kuortaneen Liikuntahotelli"],
  kuusamo: [
    "Kuusamon Lampitropiikki",
    "Kuusamon Tähti 1",
    "Kuusamon Lomaparatiisi",
    "Kuusamon Rantatropiikki",
    "Kuusamon Rantatropiikki 2",
    "Petäjälampi 6 Lodge",
    "Petäjälammenranta 7 Lodge",
  ],
  laukaa: ["Pitkäniemi III"],
  levi: [
    "Levi-Rakkavaara Club 1",
    "Rakkavaara Club Int. Ltd",
    "Abgott",
    "Aruudenia",
  ],
  naantali: ["Naantalin kylpyläranta", "Sunborn Vacation Club 1"],
  punkaharju: ["Hiekkaharju 1", "Hiekkaharju 2"],
  pyhä: ["Onninpyhä", "Pyhänhovi", "Pyhä HolySuites"],
  rönnäs: ["Rönnäs Country Club"],
  ruka: [
    "Rukan Lomakylä I",
    "RukaVillage Suites 1",
    "Kuusamon Pulkkajärvi 3",
    "Kuusamon Pulkkajärvi 4",
    "Kuusamon Pulkkajärvi 5",
    "Kuusamon Pulkkajärvi 6",
  ],
  saimaa: [
    "Anttilankaari 6",
    "Anttilankaari 8",
    "Anttilankaari 10",
    "Vipelentie 35",
    "Saimaanranta",
    "Saimaanranta 2",
    "Saimaanranta 3",
    "Saimaanrantapuisto",
    "Saimaan Keskuspuisto Lodge",
    "Saimaa Pearl Lodge 1",
    "Saimaa Spa Lodge 1",
    "Saimaa Spa Lodge 2",
    "Saimaa Spa Lodge 3",
  ],
  salla: [
    "Sallas huoneistot",
    "Sallan tähti",
    "Sallatunturin Kelorinne",
    "Sallan Eraustähti",
    "Villas Sallatunturi 1",
    "Villas Sallatunturi 2",
  ],
  saariselkä: [
    "Kermikkä",
    "Siulaselkä",
    "Kelotirro",
    "Riekonraito",
    "Laavutieva",
    "Tirrolampi",
    "Nilihonka",
    "Saariselkä Spa Lodge 2",
    "Kelotähti 1 Lodge",
    "Ruskarinne",
  ],
  tahko: [
    "Tahkotime",
    "Leppätahko",
    "Nilsiän Rentotahko",
    "Spa Suites Black",
    "Spa Suites White",
  ],
  tampere: [
    "Näsijärven Kimallus",
    "Lapiinniemi I",
    "Lapinniemi II",
    "Lapinniemi III",
    "Lapinniemi IV",
    "Lapinniemi V",
    "Lapinniemi VI",
    "Lapinniemi VII",
    "Lapinniemi VIII",
    "Lapinniemi IX",
    "Lapinniemi X",
    "Lapinniemi XI",
    "Lapinniemi XII",
    "Lapinniemi XIII",
    "Lapinniemi XIV",
    "Lapinniemi XV",
    "Lapinniemi XVI",
    "Lapinniemi XVII",
    "Lapinniemi XVIII",
    "Lapinniemi XIX",
    "Lapinniemi XX",
  ],
  turku: ["Caribia Spa Lodge 1", "HC Villas Turun Caribia"],
  vierumäki: [
    "HC Villas Vierumäki 1",
    "HC Villas Vierumäki 2",
    "Vierumäki Golf Resort",
  ],
  ylläs: [
    "Kolarin Siepakka",
    "Kesänki",
    "Kuer",
    "Muiro",
    "Musko",
    "Mokko",
    "Ylläksen Rautamajat",
    "Ylläs Saaga",
    "Ylläs viikko 1",
    "Ylläksen lomaviikot",
  ],
  ähtäri: ["Ähtärin Lomakylä", "Moksunhonka 1"],
  ulkomaat: [
    "Jardin Amadores",
    "Playa Amadores",
    "Puerto Calma",
    "Sol Amadores",
    "Vista Amadores",
    "Åre",
  ],
};
