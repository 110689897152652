import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import whitelogo from "../../assets/logo_valk.png";
import hamburger from "../../assets/white-hamburger.png"; // Hamburger icon

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const { user, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);

    if (!isMenuOpen) {
      document.body.style.overflow = "hidden"; // Disable body scrolling
    } else {
      document.body.style.overflow = ""; // Enable body scrolling
    }
  };

  const handleLogout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        withCredentials: true,
      });
      logout();
      navigate("/kirjaudu");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div
      className={`fixed top-0 w-screen h-24 sm:h-24 md:h-48 lg:h-48 xl:h-48 2xl:h-48 3xl:h-48 flex flex-col items-center z-50 transition duration-300 ${
        isScrolled ? "bg-teal-600" : "bg-transparent"
      }`}
    >
      {/* Logo section */}
      <div className="w-full flex justify-center items-center">
        <img
          src={whitelogo}
          alt="logo"
          className="w-72 sm:w-72 md:w-96 my-2 hover:cursor-pointer"
          onClick={() => navigate("/")}
        />
      </div>

      {/* Links for desktop */}
      <div className="hidden md:flex md:flex-row items-center justify-center">
        <a
          href="/"
          className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
            isScrolled
              ? "text-white hover:underline"
              : "text-black bg-white bg-opacity-80 hover:underline"
          } hover:bg-transparent`}
        >
          Etusivu
        </a>
        <a
          href="/hinnat"
          className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
            isScrolled
              ? "text-white hover:underline"
              : "text-black bg-white bg-opacity-80 hover:underline"
          } hover:bg-transparent`}
        >
          Hinnasto
        </a>
        <a
          href="/laskin"
          className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
            isScrolled
              ? "text-white hover:underline"
              : "text-black bg-white bg-opacity-80 hover:underline"
          } hover:bg-transparent`}
        >
          Lomakrediittilaskuri
        </a>
        <a
          href="/kayttoehdot"
          className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
            isScrolled
              ? "text-white hover:underline"
              : "text-black bg-white bg-opacity-80 hover:underline"
          } hover:bg-transparent`}
        >
          Käyttöehdot
        </a>

        {/* User links */}
        {user ? (
          <>
            <a
              href="/profiili"
              className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
                isScrolled
                  ? "text-white hover:underline"
                  : "text-black bg-white bg-opacity-80 hover:underline"
              } hover:bg-transparent`}
            >
              Oma sivu
            </a>
            <div
              className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
                isScrolled
                  ? "text-white hover:underline"
                  : "text-black bg-white bg-opacity-80 hover:underline"
              } hover:bg-transparent`}
            >
              <p>Lompakko: {user.credits}</p>
            </div>

            {user.isAdmin && (
              <>
                <a
                  href="/kayttajat"
                  className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
                    isScrolled
                      ? "text-white hover:underline"
                      : "text-black bg-white bg-opacity-80 hover:underline"
                  } hover:bg-transparent`}
                >
                  Käyttäjät
                </a>
                <a
                  href="/kuvat"
                  className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
                    isScrolled
                      ? "text-white hover:underline"
                      : "text-black bg-white bg-opacity-80 hover:underline"
                  } hover:bg-transparent`}
                >
                  Kuvat
                </a>
                <a
                  href="/linkit"
                  className={`m-4 transition duration-200 px-4 py-2 rounded-xl ${
                    isScrolled
                      ? "text-white hover:underline"
                      : "text-black bg-white bg-opacity-80 hover:underline"
                  } hover:bg-transparent`}
                >
                  Linkit
                </a>
              </>
            )}

            <button
              className={`m-4 px-4 py-2 rounded-xl transition duration-200 ${
                isScrolled
                  ? "bg-teal-600 text-white hover:underline"
                  : "bg-white bg-opacity-80 text-black hover:bg-transparent hover:underline"
              }`}
              onClick={handleLogout}
            >
              Kirjaudu ulos
            </button>
          </>
        ) : (
          <button
            className={`m-4 px-4 py-2 rounded-xl transition duration-200 ${
              isScrolled
                ? "bg-teal-600 text-white hover:underline"
                : "bg-white bg-opacity-80 text-black hover:bg-transparent hover:underline"
            }`}
            onClick={() => navigate("/kirjaudu")}
          >
            Kirjaudu sisään
          </button>
        )}
      </div>

      {/* Mobile elements remain unchanged */}
      {/* Menu Toggle Button for Mobile */}
      <button
        className={`py-4 md:hidden absolute top-4 right-4 focus:outline-none ${
          isScrolled ? "text-white" : "text-black"
        }`}
        onClick={toggleMenu}
      >
        <img src={hamburger} alt="menu" className="h-8 w-8" />
      </button>

      {/* Links for mobile */}
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } absolute top-24 left-0 w-full h-screen bg-white md:hidden`}
      >
        {/* Make the menu content scrollable when it exceeds the screen height */}
        <div className="w-full flex flex-col max-h-screen overflow-y-auto">
          <a
            href="/"
            className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
            onClick={toggleMenu}
          >
            Etusivu
          </a>
          <a
            href="/hinnat"
            className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
            onClick={toggleMenu}
          >
            Hinnasto
          </a>
          <a
            href="/laskin"
            className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
            onClick={toggleMenu}
          >
            Lomakrediittilaskuri
          </a>
          <a
            href="/kayttoehdot"
            className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
            onClick={toggleMenu}
          >
            Käyttöehdot
          </a>

          {/* User links */}
          {user ? (
            <>
              <a
                href="/profiili"
                className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
                onClick={toggleMenu}
              >
                Oma sivu
              </a>

              {user.isAdmin && (
                <>
                  <a
                    href="/kayttajat"
                    className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
                    onClick={toggleMenu}
                  >
                    Käyttäjät
                  </a>
                  <a
                    href="/kuvat"
                    className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
                    onClick={toggleMenu}
                  >
                    Kuvat
                  </a>
                  <a
                    href="/linkit"
                    className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
                    onClick={toggleMenu}
                  >
                    Linkit
                  </a>
                </>
              )}

              <button
                className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
                onClick={() => {
                  handleLogout();
                  toggleMenu();
                }}
              >
                Kirjaudu ulos
              </button>
            </>
          ) : (
            <button
              className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
              onClick={() => {
                navigate("/kirjaudu");
                toggleMenu();
              }}
            >
              Kirjaudu sisään
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
