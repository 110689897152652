import React, { useState } from "react";
import TimeshareInfoInput from "../components/TimeshareInfoInput";
import remove from "../assets/cross-circle.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";

const Register = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [rciBlocked, setRciBlocked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTimeshareForm, setShowTimeshareForm] = useState(false);
  const [timeshareInputs, setTimeshareInputs] = useState([
    {
      id: 0,
      place: "",
      property: "",
      startDate: "",
      endDate: "",
      rooms: "",
      sleeping: "",
      room: "",
      rci: "",
    },
  ]);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    phone: "",
    general: "",
  });

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const updateTimeshare = (index, updatedTimeshare) => {
    setTimeshareInputs((prevInputs) =>
      prevInputs.map((input, i) => (i === index ? updatedTimeshare : input))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = true;
    const newErrors = { email: "", password: "", phone: "", general: "" };

    // Validation checks
    if (!isValidEmail(email)) {
      newErrors.email = "Virheellinen sähköpostiosoite";
      valid = false;
    }

    if (!isValidPassword(password)) {
      newErrors.password = "Salasanan tulee olla vähintään 8 merkkiä pitkä";
      valid = false;
    }

    if (!isValidPhone(phone)) {
      newErrors.phone = "Virheellinen puhelinnumero";
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) return;

    // Format timeshare data
    const formattedTimeshares = timeshareInputs.map((timeshare) => {
      const startDate = new Date(timeshare.startDate);
      const endDate = new Date(timeshare.endDate);
      return {
        ...timeshare,
        startDate: isValidDate(startDate)
          ? startDate.toISOString().slice(0, 10)
          : null,
        endDate: isValidDate(endDate)
          ? endDate.toISOString().slice(0, 10)
          : null,
      };
    });

    const data = {
      name,
      email,
      password,
      phone,
      timeshares: formattedTimeshares,
    };

    console.log("Submitting registration data:", data);

    try {
      const response = await axios.post(`${apiUrl}/auth/register`, data);
      console.log(response.data);

      if (response.status === 201) {
        console.log("User registered successfully");
        await sendWelcomeEmail({ name, email });

        // Construct the identification URL
        const clientId = "bdca319b-1568-4002-95c3-74b89882cc70"; // Replace with Telia-provided client ID
        const redirectUri = "https://vaihtolomat.fi/api/tunnistaudu"; // Your backend route that will handle the code exchange
        const acrValues = "http://ftn.ficora.fi/2017/loa2";
        const state = Math.random().toString(36).substring(7); // Generate a random state value

        const identificationUrl = `https://tunnistus.telia.fi/uas/oauth2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid&state=${state}&acr_values=${acrValues}`;

        // Redirect the user to the identification service
        window.location.href = identificationUrl;
      }
    } catch (error) {
      if (error.response) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          general:
            error.response.data.message ||
            "Jokin meni pieleen. Yritä myöhemmin uudelleen.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "Jokin meni pieleen. Yritä myöhemmin uudelleen.",
        }));
      }
    }
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidPhone = (phone) => {
    return /^\d{9,15}$/.test(phone);
  };

  const isValidPassword = (password) => {
    return password.length >= 8;
  };

  const handleAddTimeshare = () => {
    setTimeshareInputs((prevInputs) => [
      ...prevInputs,
      {
        id: prevInputs.length,
        place: "",
        property: "",
        startDate: "",
        endDate: "",
        rooms: "",
        sleeping: "",
        room: "",
        rci: "",
      },
    ]);
  };

  const handleTimeshareRemove = (id) => {
    setTimeshareInputs((prevInputs) =>
      prevInputs.filter((input) => input.id !== id)
    );
  };

  const sendWelcomeEmail = async ({ name, email }) => {
    try {
      const emailData = { name, email };
      await axios.post(`${apiUrl}/emails/welcome-email`, emailData);
      console.log("Welcome email sent successfully");
    } catch (error) {
      console.log("Error sending welcome email:", error);
    }
  };

  return (
    <div className="bg-transparent min-h-screen p-4 py-8">
      <div className="flex justify-center items-center h-full">
        <div className="w-5/6 sm:w-4/6 md:w-4/6 lg:w-4/6 xl:w-2/5 3xl:w-2/6 py-12 sm:py-24 md:py-28 bg-white p-12 rounded-3xl shadow-lg">
          <div className="">
            <h2 className="text-center text-4xl lg:text-5xl mb-12 font-roboto leading-9 tracking-tight text-headline">
              Rekisteröidy
            </h2>
          </div>

          <form className="space-y-6" onSubmit={handleSubmit}>
            {/* General error message */}
            {errors.general && (
              <div className="text-red-500 text-sm text-center mb-4">
                {errors.general}
              </div>
            )}

            {/* Name input */}
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-black"
              >
                <div className="flex flex-row ml-8">
                  <p className="font-roboto text-lg lg:text-xl 3xl:ml-20 text-paragraph">
                    Nimi
                  </p>
                </div>
              </label>
              <div className="flex justify-center mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  style={{ border: "1px solid #ffa400" }}
                  className="block w-3/4 3xl:w-2/3 h-8 rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder=""
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            {/* Email input */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-black"
              >
                <div className="flex flex-row ml-8">
                  <p className="font-roboto text-lg lg:text-xl 3xl:ml-20 text-paragraph">
                    Sähköposti
                  </p>
                </div>
              </label>
              <div className="flex justify-center mt-2">
                <input
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="email"
                  required
                  style={
                    errors.email
                      ? { border: "1px solid #e53e3e" }
                      : { border: "1px solid #ffa400" }
                  }
                  className="block w-3/4 3xl:w-2/3 h-8 rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {errors.email && (
                <div className="text-red-500 text-sm mt-1 text-center">
                  {errors.email}
                </div>
              )}
            </div>

            {/* Phone input */}
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-6 text-black"
              >
                <div className="flex flex-row ml-8">
                  <p className="font-roboto text-lg lg:text-xl 3xl:ml-20 text-paragraph">
                    Puhelinnumero
                  </p>
                </div>
              </label>
              <div className="flex justify-center mt-2">
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  autoComplete="tel"
                  required
                  style={
                    errors.phone
                      ? { border: "1px solid #e53e3e" }
                      : { border: "1px solid #ffa400" }
                  }
                  className="block w-3/4 3xl:w-2/3 h-8 rounded-xl border-0 p-2 py-1.5 text-black shadow-sm ring-2 ring-inset ring-secondary ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder=""
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              {errors.phone && (
                <div className="text-red-500 text-sm mt-1 text-center">
                  {errors.phone}
                </div>
              )}
            </div>

            {/* Password input */}
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-black"
              >
                <div className="flex flex-row ml-8">
                  <p className="font-roboto text-lg lg:text-xl 3xl:ml-20 text-paragraph">
                    Salasana
                  </p>
                </div>
              </label>
              <div className="flex justify-center mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  style={
                    errors.password
                      ? { border: "1px solid #e53e3e" }
                      : { border: "1px solid #ffa400" }
                  }
                  className="block p-2 w-3/4 3xl:w-2/3 h-8 rounded-xl border-0 py-1.5 text-black shadow-sm ring-2 ring-inset ring-teal-600 ring-secondary placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder=""
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {errors.password && (
                <div className="text-red-500 text-sm mt-1 text-center">
                  {errors.password}
                </div>
              )}
            </div>

            {/* Button to reveal the Timeshare Form */}
            {!showTimeshareForm && (
              <div className="flex justify-center">
                <button
                  type="button"
                  className="flex w-2/4 justify-center rounded-3xl bg-teal-600 px-5 py-3 text-lg lg:text-xl font-roboto leading-6 text-white shadow-sm hover:bg-hoverbtn focus-visible:outline focus:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange"
                  onClick={() => setShowTimeshareForm(true)}
                >
                  Lisää omistamasi viikko
                </button>
              </div>
            )}

            {/* Timeshare info inputs */}
            {showTimeshareForm && (
              <>
                {timeshareInputs.map((input, index) => (
                  <div key={input.id} className="relative my-4">
                    <TimeshareInfoInput
                      label={input.label}
                      index={index}
                      timeshare={input}
                      updateTimeshare={updateTimeshare}
                      setRciBlocked={setRciBlocked}
                    />
                    {input.id !== 0 && (
                      <button
                        type="button"
                        className="absolute top-0 right-0 mt-4 mr-4"
                        onClick={() => handleTimeshareRemove(input.id)}
                      >
                        <img src={remove} alt="remove" className="w-6" />
                      </button>
                    )}
                  </div>
                ))}

                {/* Add new timeshare button */}
                <div className="flex justify-center mt-4">
                  <button
                    type="button"
                    className="flex w-2/4 justify-center rounded-3xl bg-teal-600 px-5 py-3 text-lg lg:text-xl font-roboto leading-6 text-white shadow-sm hover:bg-hoverbtn focus-visible:outline focus:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange"
                    onClick={handleAddTimeshare}
                  >
                    Lisää uusi viikko
                  </button>
                </div>
              </>
            )}

            {/* Modal for RCI Warning */}
            <Modal
              isVisible={showModal}
              children={
                "Et voi tallettaa osaketta, joka on liitetty RCI-pisteisiin. Suorita RCI-pistepurku ensin."
              }
              onClose={() => setShowModal(false)}
            />

            {/* Submit button */}
            <div className="flex justify-center mt-6">
              <button
                type="submit"
                className="flex w-2/4 justify-center rounded-3xl bg-teal-600 px-5 py-3 text-lg lg:text-xl font-roboto leading-6 text-white shadow-sm hover:bg-hoverbtn focus-visible:outline focus:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange"
              >
                Rekisteröidy
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
