import React, { useState } from "react";
import {
  format,
  addDays,
  startOfMonth,
  endOfMonth,
  subMonths,
  addMonths,
  isWithinInterval,
  isSameDay,
  getISOWeek,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import { fi } from "date-fns/locale";
import arrow from "../assets/arrow.png";

const DatePicker = ({ onDateChange }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedWeek, setSelectedWeek] = useState({
    start: new Date(),
    end: addDays(new Date(), 7),
  });
  const [isCollapsed, setIsCollapsed] = useState(true);

  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const goToPreviousMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const goToNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const handleWeekRangeClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleDayClick = (date) => {
    const newStart = date;
    const newEnd = addDays(newStart, 7);
    setSelectedWeek({ start: newStart, end: newEnd });
    if (onDateChange) {
      onDateChange(newStart, newEnd);
    }
  };

  const generateCalendar = () => {
    const startDay = startOfMonth(currentMonth);
    const endDay = endOfMonth(currentMonth);
    let date = startOfWeek(startDay, { locale: fi });
    const endDate = endOfWeek(endDay, { locale: fi });

    const calendar = [];
    while (date <= endDate) {
      const week = [];
      for (let i = 0; i < 7; i++) {
        week.push(date);
        date = addDays(date, 1);
      }
      calendar.push(week);
    }

    return calendar;
  };

  return (
    <div className="relative w-full max-w-xs mx-auto">
      <div
        className="cursor-pointer mb-4 flex justify-center"
        onClick={handleWeekRangeClick}
      >
        <div className="block w-full rounded-xl border-0 p-2 py-2 text-black shadow-sm ring-2 ring-inset ring-teal-600 placeholder:text-gray-400 focus:ring-3 focus:ring-inset focus:ring-teal-700 focus:outline-none text-sm leading-6 flex items-center justify-center">
          <span className="flex-grow text-center text-xs sm:text-sm">
            {format(selectedWeek.start, "dd.MM.yyyy")} -{" "}
            {format(selectedWeek.end, "dd.MM.yyyy")}
          </span>
          <img
            src={arrow}
            alt="arrow"
            className="w-3 sm:w-4 inline-block ml-2"
          />
        </div>
      </div>
      <div
        className={`bg-white rounded-3xl p-2 absolute top-0 transition-all duration-300 ease-in-out ${
          isCollapsed ? "hidden" : "max-h-[350px] z-20 shadow-lg overflow-auto"
        } w-[95vw] sm:w-[24rem] mx-auto`}
        style={{ top: "100%", left: "50%", transform: "translateX(-50%)" }}
      >
        {!isCollapsed && (
          <>
            <div className="flex justify-between items-center w-full mb-4 px-2 sm:px-4">
              <button
                className="p-1 sm:p-2 border rounded-full"
                onClick={goToPreviousMonth}
              >
                <img
                  src={arrow}
                  alt="arrow"
                  className="transform rotate-90 w-3 sm:w-4"
                />
              </button>
              <h2 className="text-sm sm:text-base">
                {capitalize(format(currentMonth, "MMMM yyyy", { locale: fi }))}
              </h2>
              <button
                className="p-1 sm:p-2 border rounded-full"
                onClick={goToNextMonth}
              >
                <img
                  src={arrow}
                  alt="arrow"
                  className="transform -rotate-90 w-3 sm:w-4"
                />
              </button>
            </div>
            <div className="grid grid-cols-8 gap-1 px-2 sm:px-4 w-full text-xs">
              <div className="text-center col-span-1">vk</div>
              {["Ma", "Ti", "Ke", "To", "Pe", "La", "Su"].map((day, index) => (
                <div key={index} className="ml-1 col-span-1">
                  {day}
                </div>
              ))}
              {generateCalendar().map((week, weekIndex) => (
                <React.Fragment key={weekIndex}>
                  <div className="text-center text-teal-600 underline font-bold flex items-center justify-center col-span-1">
                    {getISOWeek(week[0])}
                  </div>
                  {week.map((date, index) => {
                    const isSelectedDay = isWithinInterval(date, {
                      start: selectedWeek.start,
                      end: selectedWeek.end,
                    });
                    return (
                      <div
                        key={index}
                        className={`h-6 w-6 sm:h-8 sm:w-8 rounded-full flex items-center justify-center col-span-1
                          ${
                            isSelectedDay
                              ? "bg-teal-600 text-white"
                              : "hover:bg-teal-500 hover:text-white cursor-pointer"
                          }
                          ${
                            format(date, "MM") === format(currentMonth, "MM")
                              ? "text-gray-700"
                              : "text-gray-400"
                          }
                          ${
                            isSameDay(date, new Date())
                              ? "ring-2 ring-teal-600"
                              : ""
                          }`}
                        onClick={() => handleDayClick(date)}
                      >
                        {format(date, "d")}
                      </div>
                    );
                  })}
                </React.Fragment>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DatePicker;
